function getQuantityInvoiced(record) {
  let result = 0;
  if (record.invoice_lines) {
    result = Array.from(record.invoice_lines.values()).reduceRight(
      (acc, cur) => acc + cur.quantity,
      0,
    );
  }
  return result;
}

function getAmountInvoiced(record) {
  let result = 0;
  if (record.invoice_lines) {
    result = Array.from(record.invoice_lines.values()).reduceRight(
      (acc, cur) => acc + cur.untaxed_amount,
      0,
    );
  }
  return result;
}

function getQuantityStock(record) {
  let result = 0;
  if (record.invoice_lines) {
    result = Array.from(record.invoice_lines.values()).reduceRight(
      (acc, cur) => acc + cur.quantity,
      0,
    );
  }
  return result;
}

const getView = (config) => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    form_action_add: "modal",
    model: "sale.line",
    row_selectable: false,
    table_action: ["edit"],
    form_action: ["add"],
    target: "sale",
    selectable: true,
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        required: true,
        recName: "name",
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        readOnly: true,
      },
      quantity: {
        type: "numeric",
        required: true,
        default: 1,
      },
      "invoice_lines.quantity": {
        type: "char",
        function: getQuantityInvoiced,
        search: true,
      },
      "invoice_lines.untaxed_amount": {
        type: "char",
        function: getAmountInvoiced,
        search: true,
      },
      "moves.quantity": {
        type: "char",
        function: getQuantityStock,
        search: true,
      },
      // reservate: {
      //   type: "button",
      //   method: onPrintBrowser,
      //   // visible: visiblePrint,
      //   // size: "w-32",
      //   color: "amber",
      //   iconRight: "fi fi-rr-print",
      //   loading: false,
      // },

      unit_price: { type: "numeric", readOnly: true },
      unit_price_w_tax: { type: "numeric", readOnly: true },
      amount_w_tax: { type: "numeric", readOnly: true },
      discount: { type: "numeric", readOnly: true },
    },
    webtree: [
      { name: "product", width: "40%" },
      { name: "unit", width: "10%" },
      { name: "quantity", width: "10%" },
      { name: "amount_w_tax", width: "23%" },
      { name: "invoice_lines.quantity", width: "23%" },
      // { name: "invoice_lines.amount", width: "23%" },
      { name: "moves.quantity", width: "23%" },
    ],
    webform: [
      { name: "product" },
      { name: "unit" },
      { name: "quantity" },
      { name: "unit_price" },
      { name: "unit_price_w_tax" },
      { name: "amount_w_tax" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
