// Stock Move Line
import store from "store";
import mainStore from "store/mainStore";

const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.type", "=", "goods"],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.type", "=", "goods"],
    ],
  ];
  return search;
};

const getCategoryUom = (value, rec) => {
  let categoryUom = rec.uom.category;
  const search = [["category", "=", categoryUom]];
  return search;
};

const onChangeProduct = async (record, storeRec) => {
  const activeRecord = mainStore.getState().record;
  if (record.product) {
    const uom = record.product["default_uom."];
    if (uom) {
      // tryton v7
      // record.unit = uom;
      // storeRec.unit = uom.id;

      // tryton v6
      record.uom = uom;
      storeRec.uom = uom.id;
    }
    if (activeRecord) {
      record.from_location = activeRecord.from_location;
      record.to_location = activeRecord.to_location;
      storeRec.from_location = activeRecord.from_location.id;
      storeRec.to_location = activeRecord.to_location.id;
    }
  } else {
    // tryton v7
    // record.unit = null;
    // storeRec.unit = null;
    // tryton v6
    record.unit = null;
    storeRec.unit = null;
    record.from_location = null;
    record.to_location = null;
    storeRec.from_location = null;
    storeRec.to_location = null;
  }
};

export default {
  form_action_add: "modal",
  model: "stock.move",
  row_selectable: false,
  form_action: ["add"],
  table_action: ["edit", "add", "delete"],
  target: "shipment",
  webfields: {
    product: {
      type: "many2one",
      model: "product.product",
      recSearch: getProduct,
      withChange: onChangeProduct,
      attrs: ["id", "default_uom.name", "default_uom.category", "name"],
      required: true,
    },
    quantity: { type: "float", decimalPlaces: 2, required: true },
    uom: {
      // unit: {
      type: "many2one",
      model: "product.uom",
      recSearch: getCategoryUom,
    },
    company: {
      default: store.get("ctxSession") && store.get("ctxSession").company,
    },
    from_location: {
      type: "many2one",
      model: "stock.location",
    },
    to_location: {
      type: "many2one",
      model: "stock.location",
    },
  },
  webtree: [
    { name: "product", width: "60%" },
    { name: "uom", width: "10%" },
    { name: "quantity", width: "20%" },
  ],
  webform: [{ name: "product" }, { name: "uom" }, { name: "quantity" }],
};
